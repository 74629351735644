<script>
import abstractField from "vue3-form-generator";
import SelectSquareIcon from "@/Components/Icons/SelectSquareIcon.vue";

export default {
    mixins: [ abstractField ],
    components: {
        "select-square": SelectSquareIcon,
    },
    data() {
        return {
            inputMoreInfos: this.schema.values.find(e => e.value == this.schema.inputMore.buttonValue),
        }
    },
    watch: {
        schema() {
            this.inputMoreInfos = this.schema.values.find(e => e.value == this.schema.inputMore.buttonValue);
        },
    },
    methods: {
        onSelection($event) {
            let newVal = $event.target.options[$event.target.options.selectedIndex]._value;
            if(!this.value) this.value = { button: "", input: "" };
            this.value.button = newVal;
            if(newVal !== this.schema.inputMore.buttonValue) this.value.input = "";
        },
        onInput($event) {
            if(!this.value) this.value = { button: "", input: "" };
            this.value.input = $event.target.value;
        },
    },
};
</script>

<template>
    <div class="wrapper">
        <select
            :id="getFieldID(schema)"
            :disabled="disabled"
            :name="schema.model"
            @change="onSelection"
            class="form-control"
        >
            <option
                v-if="!schema.selectOptions.hideNoneSelectedText"
                :disabled="schema.required"
                :value="null"
            >
                {{ schema.selectOptions.noneSelectedText || __('<No value selected>') }}
            </option>
            <template v-for="(item, k) in schema.values" :key="k">
                <option :value="item.value" :selected="value && item.value === value.button ? true : false">{{ item.name }}</option>
            </template>
        </select>
        <div v-if="value && schema.inputMore.buttonValue === value.button" class="mt-2 form-custom" :class="{ 'disabled': disabled }">
            <label :for="getFieldID(schema.inputMore)">{{ schema.inputMore.label }}</label>
            <input class="form-control"
                :id="getFieldID(schema.inputMore)"
                :type="schema.inputMore.inputType"
                :value="value && value.input"
                @input="onInput"
                :disabled="disabled"
                :maxlength="schema.inputMore.maxlength"
            >
            <span class="hint"> {{ schema.inputMore.hint }}</span>
        </div>
        <div v-if="formOptions.showWithInputFields && !(value && schema.inputMore.buttonValue === value.button)" class="mt-2">
            <span class="flex items-center italic text-gray-600"><select-square class="h-5 w-5 fill-gray-600 mr-1" />{{ inputMoreInfos.value }}/{{ inputMoreInfos.name }}, {{ schema.inputMore.label }}</span>
            <input class="form-control"
                :id="getFieldID(schema.inputMore)"
                :type="schema.inputMore.inputType"
                :value="value && value.input"
                :disabled="disabled"
                :maxlength="schema.inputMore.maxlength"
            >
            <span class="hint"> {{ schema.inputMore.hint }}</span>
        </div>
    </div>
</template>
