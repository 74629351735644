import '../css/app.css';
import $ from 'jquery';

import '../css/custom.css';
import 'floating-vue/dist/style.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue3-easy-data-table/dist/style.css';
import 'vue3-form-generator/dist/style.css';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { Ziggy } from './ziggy';

import axios from 'axios';
import translation from '@/Composables/base';
import FloatingVue from 'floating-vue';
// import { createPopper } from "@popperjs/core";
// import * as te from "tw-elements";

// import igv from '@/igv.js/dist/igv.esm.min.js';
import igv from 'igv';
// To enable VueFormGenerator
import VueFormGenerator from "vue3-form-generator";
import fieldChecklistWithInput from '@/Components/VFGFields/fieldChecklistWithInput.vue';
import fieldRadiosWithInput from '@/Components/VFGFields/fieldRadiosWithInput.vue';
import fieldSelectWithInput from '@/Components/VFGFields/fieldSelectWithInput.vue';

// window.cspNonce = document.querySelector('meta[name="csp-nonce"]').getAttribute('content');

window.axios = axios;
window.jQuery = window.$ = $;
// window.createPopper = createPopper;
// window.te = te;
window.igv = igv;

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            // .use(Ziggy)
            .use(ZiggyVue, Ziggy)
            .use(FloatingVue, {
                themes: {
                    'my-tooltip': {
                        $extend: 'tooltip',
                        $resetCss: true,
                    },
                },
            })
            .mixin(translation)
            .use(VueFormGenerator)
            .mixin({ components: { fieldChecklistWithInput, fieldRadiosWithInput, fieldSelectWithInput } })
            .mount(el);
    },
    progress: {
        color: '#4B5563',
        showSpinner: true
    },
});

