<script>
import abstractField from "vue3-form-generator";
import CheckSquareIcon from "@/Components/Icons/CheckSquareIcon.vue";

export default {
    mixins: [ abstractField ],
    components: {
        "check-square": CheckSquareIcon,
    },
    data() {
        return {
            inputMoreInfos: this.schema.values.find(e => e.value == this.schema.inputMore.buttonValue),
        }
    },
    watch: {
        schema() {
            this.inputMoreInfos = this.schema.values.find(e => e.value == this.schema.inputMore.buttonValue);
        },
    },
    methods: {
        onChanged(event, item) {
            if(!this.value) this.value = { buttons: [], input: "" };
            if (event.target.checked) {
                this.value.buttons.push(item.value);
            } else {
                this.value.buttons.splice(this.value.buttons.indexOf(item.value), 1);
            }
            if(this.value.buttons && this.value.buttons.indexOf(this.schema.inputMore.buttonValue) === -1) this.value.input = "";
        },
        onInput($event) {
            if(!this.value) this.value = { buttons: [], input: "" };
            this.value.input = $event.target.value;
        },
    },
};
</script>

<template>
    <div class="wrapper">
        <div class="listbox" :disabled="disabled">
            <div class="list-row" v-for="(item, k) in schema.values" :key="k">
                <label>
                    <input
                        :id="getFieldID(schema, true)"
                        type="checkbox"
                        :disabled="disabled"
                        :name="item.value"
                        @change="onChanged($event, item)"
                        :checked="(value && value.buttons.indexOf( item.value) !== -1) ? true : false"
                    >
                    {{ item.name }}
                </label>
                <div v-if="value && value.buttons.indexOf( schema.inputMore.buttonValue) !== -1 && schema.inputMore.buttonValue === item.value" class="mt-2 form-custom" :class="{ 'disabled': disabled }">
                    <label :for="getFieldID(schema.inputMore)">{{ schema.inputMore.label }}</label>
                    <input class="form-control"
                        :id="getFieldID(schema.inputMore)"
                        :type="schema.inputMore.inputType"
                        :value="value && value.input"
                        @input="onInput"
                        :disabled="disabled"
                        :maxlength="schema.inputMore.maxlength"
                    >
                    <span class="hint"> {{ schema.inputMore.hint }}</span>
                </div>
            </div>
        </div>
        <div v-if="formOptions.showWithInputFields && !(value && value.buttons.indexOf( schema.inputMore.buttonValue) !== -1)" class="mt-2">
            <span class="flex items-center italic text-gray-600"><check-square class="h-5 w-5 fill-gray-600 mr-1" />{{ inputMoreInfos.value }}/{{ inputMoreInfos.name }}, {{ schema.inputMore.label }}</span>
            <input class="form-control"
                :id="getFieldID(schema.inputMore)"
                :type="schema.inputMore.inputType"
                :value="value && value.input"
                :disabled="disabled"
                :maxlength="schema.inputMore.maxlength"
            >
            <span class="hint"> {{ schema.inputMore.hint }}</span>
        </div>
    </div>
</template>
