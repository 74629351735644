<script>
import abstractField from "vue3-form-generator";
import CheckCircleIcon from "@/Components/Icons/CheckCircleIcon.vue";

export default {
    mixins: [ abstractField ],
    components: {
        "check-circle": CheckCircleIcon,
    },
    data() {
        return {
            inputMoreInfos: this.schema.values.find(e => e.value == this.schema.inputMore.buttonValue),
        }
    },
    watch: {
        schema() {
            this.inputMoreInfos = this.schema.values.find(e => e.value == this.schema.inputMore.buttonValue);
        },
    },
    methods: {
        onSelection(item) {
            if(!this.value) this.value = { button: "", input: "" };
            this.value.button = item.value;
            if(item.value !== this.schema.inputMore.buttonValue) this.value.input = "";
        },
        onInput($event) {
            if(!this.value) this.value = { button: "", input: "" };
            this.value.input = $event.target.value;
        },
    },
};
</script>

<template>
    <div class="wrapper">
        <div class="radio-list" v-for="(item, k) in schema.values" :key="k">
            <label>
                <input
                    :id="getFieldID(schema, true)"
                    type="radio"
                    :disabled="disabled"
                    :name="schema.model"
                    @click="onSelection(item)"
                    :value="item.value"
                    :checked="value && item.value === value.button ? true : false"
                >
                {{ item.name }}
            </label>
            <div v-if="value && schema.inputMore.buttonValue === value.button && schema.inputMore.buttonValue === item.value" class="mt-2 form-custom" :class="{ 'disabled': disabled }">
                <label :for="getFieldID(schema.inputMore)">{{ schema.inputMore.label }}</label>
                <input class="form-control"
                    :id="getFieldID(schema.inputMore)"
                    :type="schema.inputMore.inputType"
                    :value="value && value.input"
                    @input="onInput"
                    :disabled="disabled"
                    :maxlength="schema.inputMore.maxlength"
                >
                <span class="hint"> {{ schema.inputMore.hint }}</span>
            </div>
        </div>
        <div v-if="formOptions.showWithInputFields && !(value && schema.inputMore.buttonValue === value.button)" class="mt-2">
            <span class="flex items-center italic text-gray-600"><check-circle class="h-5 w-5 fill-gray-600 mr-1" />{{ inputMoreInfos.value }}/{{ inputMoreInfos.name }}, {{ schema.inputMore.label }}</span>
            <input class="form-control"
                :id="getFieldID(schema.inputMore)"
                :type="schema.inputMore.inputType"
                :value="value && value.input"
                :disabled="disabled"
                :maxlength="schema.inputMore.maxlength"
            >
            <span class="hint"> {{ schema.inputMore.hint }}</span>
        </div>
    </div>
</template>
